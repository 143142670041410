.fifth-section-container {
    margin-top: 80px;
    color: white;
    background-color: rgb(19, 19, 19);
}

.fifth-section-title {
    padding-top: 50px;
    text-align: center;
    font-size: 60px;
}
.fifth-socials-div {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.fifth-x-link {
    text-decoration: none;
    color: black;
    padding: 10px 20px;
    background: white;
    border-radius: 50px;
    margin-right: 50px;
    font-size: 40px;
}

.fifth-telegram-link {
    text-decoration: none;
    color: black;
    padding: 10px 20px;
    background: white;
    border-radius: 50px;
    font-size: 40px;
}

.disclaimer-div {
    padding: 50px 30px 30px 30px;
}


@media (max-width: 1250px) {
    .fifth-section-title {
        font-size: 50x;
    }
    
}

@media (max-width: 800px) {
    .fifth-section-title {
        font-size: 48px;
    }
    .fifth-x-link {
        padding: 8px 16px;
        margin-right: 40px;
        font-size: 30px;
    }
    
    .fifth-telegram-link {
        padding: 8px 16px;
        font-size: 30px;
    }
}

@media (max-width: 520px) {
    .fifth-section-title {
        padding-top: 30px;
        font-size: 36px;
    }
    .fifth-x-link {
        padding: 8px 16px;
        margin-right: 40px;
        font-size: 20px;
    }
    
    .fifth-telegram-link {
        padding: 8px 16px;
        font-size: 20px;
    }
    .disclaimer-div {
        font-size: 14px;
    }
}