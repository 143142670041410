.first-section-container {
    background-image: url('../images/usa_flag.svg');
    background-size: cover;
    /* or 'contain', depending on your needs */
    background-position: left center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
}

.first-section-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.first-section-image-container {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 52%;
    left: 49%;
}

.first-section-image {
    height: 400px;
    filter: drop-shadow(3px 4px 10px rgba(0, 0, 0, 0.5));
}

.first-section-text-one {
    position: relative;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 25%;
    font-size: 70px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 4px;
}

.first-section-text-one h1 {
    margin: 0;
    padding: 0;
    -webkit-text-stroke: 3px black;
}

.first-section-text-two {
    position: relative;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 65%;
    font-size: 55px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.first-section-text-two h2 {
    margin: 0;
    padding: 0;
    letter-spacing: 3px;
    -webkit-text-stroke: 3px black;
}


@media (max-width: 1250px) {
    .first-section-text-one h1 {
        font-size: 65px;
        letter-spacing: 3px;
    }

    .first-section-text-two h2 {
        font-size: 50px;
        letter-spacing: 2px;
    }

    .first-section-image {
        height: 350px;
    }

    .first-section-image-container {
        top: 48%;
    }
}

@media (max-width: 800px) {
    .first-section-container {
        height: 60vh;
    }
    .first-section-image {
        height: 250px;
    }
    .first-section-text-two h2 {
        letter-spacing: 2px;
        -webkit-text-stroke: 1px black;
        font-size: 36px;
    }
    .first-section-image-container {
        top: 56%;
    }

    .first-section-text-one h1 {
        font-size: 60px;
        -webkit-text-stroke: 1px black;
    }
    .first-section-text-two {
        top: 70%;
    }
    .first-section-text-one {
        top: 26%;
    }
}


@media (max-width: 520px) {
    .first-section-container {
        height: 40vh;
    }

    .first-section-image {
        height: 150px;
    }

    .first-section-image-container {
        top: 60%;
    }
    .first-section-text-one h1 {
        font-size: 45px;
        letter-spacing: 2px;
    }
    .first-section-text-two h2 {
        font-size: 22px;
        letter-spacing: 2px;
    }
    .first-section-text-one {
        top: 38%;
    }
    .first-section-text-two {
        top: 68%;
    }
}