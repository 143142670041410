.film-reel-container {
    width: 100%;
    display: inline-block;
    position: relative;
    height: 100px;
}

.film-reel-wrapper {
    width: 100%;
    display: block;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: -10px;
}

.film-reel-image {
    width: 100%;
    height: auto;
    display: hidden;
}