.donala-reel-container {
    width: 100%;
    margin-bottom: 20px;
}

.donala-image {
    width: 18%;
    margin: 0 1%;
}

@media (max-width: 520px) {
    .disappear {
        display: none;
    }
    .donala-image {
        width: 23%;
        margin: 0 1%;
    }
}

