.second-section-container {
    margin-top: 200px;
    color: #303c90;
    padding: 50px 5% 80px 5%;
}

.second-section-title {
    font-size: 80px;
}

.second-section-title h2 {
    margin: 0;
    padding: 0;
}

.second-section-subsitle {
    font-size: 40px;
}

.second-section-subsitle h3 {
    margin: 10px 0;
    padding: 0;
}

.second-section-description {
    margin-top: 20px;
    font-size: 36px;
    width: 80%;
}

@media (max-width: 1250px) {
    .second-section-title {
        font-size: 60px;
    }
    .second-section-container {
        margin-top: 160px;
    }
    .second-section-subsitle {
        font-size: 30px;
    }
    .second-section-description {
        font-size: 24px;
    }
}

@media (max-width: 800px) {
    .second-section-title {
        font-size: 54px;
    }
    .second-section-container {
        margin-top: 0px;
    }
    .second-section-subsitle {
        font-size: 26px;
    }
    .second-section-description {
        font-size: 22px;
    }
    .second-section-description {
        width: 90%;
    }
}

@media (max-width: 520px) {
    .second-section-title {
        font-size: 38px;
    }
    .second-section-subsitle {
        font-size: 20px;
    }
    .second-section-description {
        font-size: 18px;
    }
    .second-section-description {
        width: 94%;
    }
    .second-section-container {
        margin-top: 0px;
        padding: 0px 5% 80px 5%;
    }
}