.navbar {
    position: fixed;
    width: 100%;
    top: 20px;
    z-index: 1;
}

.navbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background: transparent;
}

.logo-wrapper {
    height: 48px;
    width: 48px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    border: 2px solid white;
}

.navbar-image-container {
    border-radius: 50px;
    padding-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    margin-right: 50px;
    border: 1px solid black;
    background: #303c90;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.donala-logo {
    height: 70px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 65%;
    left: 50%;
}

.logo-title {
    color: white;
    font-size: 34px;
    margin: 0;
    padding: 0;
    margin-left: 14px;
    margin-bottom: 3px;
}

.navbar-nav-container {
    border: 1px solid black;
    padding: 2px 2px;
    border-radius: 40px;
    background: #303c90;
    color: white;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
}

.nav-links-container {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 30px;
}

.nav-links-container li{
    display: inline-block;
    margin-right: 10px;
    padding: 8px 20px;
    border-radius: 50px;
}

.nav-links-container li:hover{
    background-color: #fe2a2e;
    cursor: pointer;
}

.nav-links-container li:last-child{
    margin-right: 0;
}

.socials-and-buy-container {
    display: flex;
    align-items: center;
    margin-right: 32px;
}

.socials-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.nav-socials-x {
    font-size: 26px;
    margin-right: 15px;
    border: 1px solid black;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    background-color: #303c90;
    border: 2px solid black;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
}

.nav-socials-x:hover {
    background-color: #fe2a2e;
}

.nav-socials-x a{
    text-decoration: none;
    color: white;
}

.nav-socials-telegram {
    font-size: 44px;
    border: 1px solid black;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #303c90;
    border: 2px solid black;
    position: relative;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
}

.nav-socials-telegram a{
    z-index: 10;
    text-decoration: none;
    color: #303c90;
    margin-top: 3px;
}

.telegram-icon {
    z-index: 10;
    color: #303c90;
}

.telegram-icon:hover {
    color: #fe2a2e;
}

.telegram-backing {
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    width: 42px;
    height: 42px;
    border-radius: 50%;
}

.navbar-buy-container {
    border: 1px solid black;
    font-size: 22px;
    /* line-height: 22px; */
    height: 40px;
    padding: 3px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    background-color: #303c90;
    color: white;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
}

.navbar-buy-container a{
    text-decoration: none;
    color: white;
    padding: 8px 24px;
    border-radius: 50px;
}

.navbar-buy-container a:hover{
    background-color: #fe2a2e;
}

.navbar-buy-container h3{
    padding: 0;
    margin: 0;
}


@media (max-width: 1250px) {
    .navbar-nav-container {
        display: none;
    }
}

@media (max-width: 800px) {
    .navbar-buy-container {
        display: none;
    }
    .navbar {
        top: 10px;
    }
    .logo-wrapper {
        height: 38px;
        width: 38px;
        border: 1px solid white;
    }
    
    .navbar-image-container {
        margin-left: 20px;
    }
    .logo-title {
        font-size: 28px;
    }
    .nav-socials-x {
        margin-right: 12px;;
    }
    .socials-container {
        margin-right: 5px;
    }
}