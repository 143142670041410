.fourth-section-container {
    margin: 5%;
    margin-top: 100px; 
    color: #303c90;;
    font-size: 40px;
}

.fourth-section-title {
    font-size: 80px;
}

.fourth-section-title h2 {
    padding: 0;
    margin: 0;
}

.tokenomics-container {
    margin-top: 20px;
}

.contact-address-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 10px solid #fe2a2e;
    padding: 5px 10px;
    border-radius: 50px;
}

.contact-address-title {
    width: 30%;
}

.contact-address-rest {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.copy-button-ca {
    height: 100%;
    background: #303c90;
    border: none;
    padding: 5px 50px;
    font-size: 40px;
    color: white;
    border-radius: 30px;
    cursor: pointer;
}

.tokenomics-rest-weapper {
    display: flex;
    margin-top: 20px;
}

.tokenomics-special-container {
    border: 10px solid #fe2a2e;
    width: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    border-radius: 40px;
}

.center-margin {
    margin: 0 2%;
}

@media (max-width: 1250px) {
    .fourth-section-container {
        padding-top: 30px;
        margin-top: 20px;
        padding-bottom: 70px;
    }
    .contact-address-container {
        border: 8px solid #fe2a2e;
        font-size: 24px;
    }
    .fourth-section-title {
        font-size: 60px;
    }
    .tokenomics-special-container {
        font-size: 30px;
        border: 8px solid #fe2a2e;
    }
    
}

@media (max-width: 800px) {
    .fourth-section-container {
        margin: 16px;
    }
    .fourth-section-title {
        font-size: 48px;
    }
    .contact-address-container {
        font-size: 16px;
    }
    .contact-address-container {
        border: 4px solid #fe2a2e;
    }
    .tokenomics-special-container {
        font-size: 24px;
        border: 4px solid #fe2a2e;
    }
    .copy-button-ca {
        padding: 4px 20px;
        font-size: 24px;
    }
}

@media (max-width: 520px) {
    .fourth-section-container {
        padding-top: 20px;
        margin-top: 10px;
        padding-bottom: 10px;
    }
    .contact-address-container {
        border: 4px solid #fe2a2e;
        font-size: 12px;
    }
    .fourth-section-title {
        font-size: 38px;
    }
    .tokenomics-special-container {
        font-size: 16px;
        border: 4px solid #fe2a2e;
    }
    .copy-button-ca {
        padding: 3px 20px;
        font-size: 18px;
    }
    .tokenomics-rest-weapper {
        margin-top: 10px;
    }
}
